
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.VuePagination {
    text-align: center;
}

.VueTables__search {
    margin-left: 20px;
}

.VueTables__limit {
    margin-right: 20px;
}

.footer {
    text-align: center;
}

.breadcrumb {
    background-color: #fff;
}